import styled from 'styled-components'

const StyledField = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid;
  outline: none;
  font-size: 18px;
  width: 100%;

  @media (max-width: 550px) {
    width: 100%;
    max-width: 100%;

  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 100%;

  @media (max-width: 550px) {
    width: 90%;
    gap: 10px;
  }
`
const Label = styled.div`
  margin-bottom: 7px;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
`

const RequiredLabel = styled.span`
  color: #FF0000;
`

const StyledError = styled.span`
  margin-top: 5px;
  color: #FF0000;
  font-size: 14px
`

export { StyledField, StyledError, Container, Label, RequiredLabel }
