import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { StyledError, Container, Label, RequiredLabel } from './styles'
import MaskedInput from './MaskedInput'

interface Props {
  name: string,
  label?: string,
  required?: any,
  mask?: string,
  placeholder: string
}

function NumberField({ name, label, required, mask, placeholder, ...props }: Props) {
  return (
    <Container>
      <Label>
        {label || name}
        {required && <RequiredLabel>*</RequiredLabel>}
      </Label>

      <Field
        component={MaskedInput}
        mask={mask}
        placeholder={placeholder}
        name={name}
      />
      <ErrorMessage name={name} component={StyledError} />
    </Container>
  )
}

export default NumberField
