import React, { ChangeEvent } from 'react'
import { Radio, RadioGroup, FormControlLabel, Stack } from '@mui/material'

import { Container, Label , RequiredLabel} from './styles'
import { useField, useFormikContext } from 'formik'

interface RadioOption {
  label: string
  value: 'cpf' | 'cnpj'
  name: string
}

interface Props {
  label: string,
  name: string,
  options: RadioOption[],
  required?: any
}

function ColaboratorIdentificator({ label, name, options, required }: Props) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.value);
    if (event.target.value === 'cnpj') {
      setFieldValue('colaboratorCpf', '');
    } else {
      setFieldValue('colaboratorCnpj', '');
    }
  };

  return (
    <Container>
      <Label>
        {label}
        {required && <RequiredLabel>*</RequiredLabel>}
      </Label>
      <Stack
        direction='row'
        sx={{
          ml: '5px'
        }}
      >
        <RadioGroup {...field} onChange={handleChange}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Stack>
    </Container>
  )
}

export default ColaboratorIdentificator
