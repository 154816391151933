import React from 'react'
import { Form, Formik, FormikValues } from 'formik'
import * as yup from 'yup'

import api from '../../services/api'

import FormData from '../../interfaces/FormData'

import Header from '../Header'
import TextField from '../TextField'
import NumberField from '../NumberField'
import ColaboratorIdentificator from '../ColaboratorIdentificator'

import { SnackbarProvider, enqueueSnackbar } from 'notistack'

import { Container, Content, Row, StyledFooter, Button } from './styles'

function App() {
  const initialValues: FormData = {
    name: '',
    storeName: '',
    cnpj: '',
    storePhone: '',
    ownerPhone: '',
    email: '',
    colaborator: '',
    colaboratorCpf: '',
    colaboratorCnpj: '',
    colaboratorEmail: '',
    colaboratorPhone: '',
    colaboratorIdentificator: 'cnpj',
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required('Nome obrigatório!'),
    storeName: yup.string().required('Nome da Farmácia obrigatório!'),
    cnpj: yup.string().required('CNPJ obrigatório!'),
    storePhone: yup
      .string()
      .min(10, 'O mínimo de caracteres é 10!')
      .max(14, 'O limite de caracteres é 14!'),
    ownerPhone: yup
      .string()
      .min(10, 'O mínimo de caracteres é 11!')
      .required('Telefone obrigatório!'),
    email: yup.string().required('E-mail obrigatório!'),
    colaborator: yup.string().required('O nome do representante é obrigatório!'),
    colaboratorCpf: yup.string().when('colaboratorIdentificator', {
      is: 'cpf',
      then: yup.string().required('O CPF do representante é obrigatório!'),
      otherwise: yup.string(),
    }),
    colaboratorCnpj: yup.string().when('colaboratorIdentificator', {
      is: 'cnpj',
      then: yup.string().required('O CNPJ do representante é obrigatório!'),
      otherwise: yup.string(),
    }),
    colaboratorEmail: yup.string().required('E-mail obrigatório!'),
    colaboratorPhone: yup
      .string()
      .min(10, 'O mínimo de caracteres é 11!')
      .required('Telefone obrigatório!'),
    colaboratorIdentificator: yup.string(),
  });

  const handleSubmit = async (values: FormikValues) => {
    try {
      const response = await api.post('/lead', values)
      if (response.status === 201) {
        enqueueSnackbar('Formulário enviado com sucesso!', {
          variant: 'success'
        })
      }
    } catch (error: any) {
      if (error.response.data.error === 'cnpj_already_used') {
        enqueueSnackbar('Esse CNPJ já foi cadastrado!', {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Não foi possível cadastrar o Lead!', {
          variant: 'error'
        })
      }
      console.error(error)
    }
  }

  return (
    <Container>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        autoHideDuration={3500}
      />
      <Content>
        <Header />
        <Formik
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values)
            resetForm()
          }}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isSubmitting }) => (
            <Form style={{ width: '90%' }}>
              <Row>
                <TextField
                  name='name'
                  label='Nome do responsável da Farmácia?'
                  placeholder='João'
                  required
                />
              </Row>
              <Row>
                <TextField
                  name='storeName'
                  label='Preencha o nome da  farmácia?'
                  placeholder='Farmácia'
                  required
                />
              </Row>
              <Row>
                <NumberField
                  name='cnpj'
                  label='CNPJ:'
                  mask='99.999.999/9999-99'
                  placeholder='xx.xxx.xxx/xxxx-xx'
                  required
                />
              </Row>
              <Row>
                <NumberField
                  name='storePhone'
                  label='Telefone da Farmácia'
                  placeholder='(xx)xxxx-xxxx'
                />
              </Row>
              <Row>
                <NumberField
                  name='ownerPhone'
                  label='Telefone (De preferência com Whats-App)'
                  mask='(99)99999-9999'
                  placeholder='(xx)xxxxx-xxxx'
                  required
                />
              </Row>
              <Row>
                <TextField
                  name='email'
                  label='Qual é o e-mail da farmácia?'
                  placeholder='seu@email.com'
                  required
                />
              </Row>
              <Row>
                <TextField
                  name='colaborator'
                  placeholder='Seu nome'
                  label='Representante que indicou a MyPharma:'
                  required
                />
              </Row>
              <Row>
                <ColaboratorIdentificator
                  label='Regime de Contrato?'
                  name='colaboratorIdentificator'
                  options={[{ label: 'PJ', value: 'cnpj', name: 'colaboratorCnpj' }, { label: 'CLT', value: 'cpf', name: 'colaboratorCpf' }]}
                  required
                />
              </Row>
              <Row>
                {(values.colaboratorIdentificator === 'cnpj')
                  && <NumberField
                    name='colaboratorCnpj'
                    label='CNPJ do Representante:'
                    mask='99.999.999/9999-99'
                    placeholder='xx.xxx.xxx/xxxx-xx'
                    required={values.colaboratorIdentificator === 'cnpj' ? true : false}
                  />
                }
                {(values.colaboratorIdentificator === 'cpf')
                  && <NumberField
                    name='colaboratorCpf'
                    label='CPF do Representante:'
                    mask='999.999.999-99'
                    placeholder='xxx.xxx.xxx-xx'
                    required={values.colaboratorIdentificator === 'cpf' ? true : false}
                  />}
              </Row>
              <Row>
                <NumberField
                  name='colaboratorPhone'
                  label='Telefone Celular do Representante (De preferência com Whats-App)'
                  mask='(99)99999-9999'
                  placeholder='(xx)xxxxx-xxxx'
                  required
                />
              </Row>
              <Row>
                <TextField
                  name='colaboratorEmail'
                  label='Seu e-mail'
                  placeholder='seuemail@email.com'
                  required
                />
              </Row>
              <StyledFooter>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                >
                  Enviar
                </Button>
              </StyledFooter>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default App;
