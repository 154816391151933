import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 100%;

  @media (max-width: 550px) {
    width: 90%;
    gap: 10px;
  }
`

const Label = styled.div`
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
`
const RequiredLabel = styled.span`
  color: #FF0000;
`

export { Container, Label, RequiredLabel }
