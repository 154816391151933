import styled from 'styled-components'

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 15px;
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 10px;
  }

  @media (max-width: 1000px) {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
`

const ImageContent = styled.img`
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`
const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
`
const Paragraph = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
`

export { HeaderContent, ImageContent, Title, Paragraph }
