import styled from 'styled-components'

const Container = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  background-color: #FFFFFF;
  width: 40%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 1px 2px 1px;
  padding: 30px 0;
  border-radius: 13px;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 550px) {
    width: 100%;
    max-width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  gap: 20px;
  margin-left: auto;

  @media (max-width: 550px) {
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

const StyledFooter = styled.div`
  text-align: start;
  padding: 10px;
  margin-top: 10px;
  margin-left: 11px;
  display: flex;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

const Button = styled.button`
  width: 100%;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  background-color: #2480ff;
  color: white;
  border: 1px solid black;
  border-radius: 5px;
  margin-left: auto;

  @media (max-width: 550px) {
    font-size: 16px;
    padding: 6px;
  }
`

export { Container, Content, Row, StyledFooter, Button }
