import React from 'react'
import { StyledMaskField } from './styles'

function MaskedInput({ field, form, ...props }: any) {
  return (
    <StyledMaskField
      {...field}
      {...props}
      onChange={(e) => {
        field.onChange(e);
        form.setFieldValue(field.name, e.target.value);
      }}
      onBlur={(e) => {
        field.onBlur(e);
        form.setFieldTouched(field.name, true);
      }}
    />
  );
}

export default MaskedInput;
