import React from 'react'
import Banner from '../../img/banner.png'
import { HeaderContent, ImageContent, Title, Paragraph } from './styles'

function Header() {
  return (
    <>
      <HeaderContent>
      <ImageContent src={Banner} alt={'Banner MyPharma'} />
        <Title>Formulário para Contato MyPharma</Title>
        <Paragraph>Assim que recebermos os dados, entraremos em contato!</Paragraph>
      </HeaderContent>
    </>
  )
}

export default Header
