import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { StyledField, StyledError, Container, Label, RequiredLabel } from './styles'

interface Props {
  name: string,
  type?: string,
  label?: string,
  required?: any
  placeholder?: string
}

function TextField({ name, type = '', label, required, placeholder, ...props }: Props) {
  return (
    <Container>
      <Label>
        {label || name}
        {required && <RequiredLabel>*</RequiredLabel>}
      </Label>

      <Field
        as={StyledField}
        name={name}
        type={type}
        placeholder={placeholder}
        {...props}
      />
      <ErrorMessage name={name} component={StyledError}/>
    </Container>
  )
}

export default TextField
